import styles from './navbar.module.scss';
import { Link } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';
import CompanyLogo from '@assets/icons/logo.svg';
import { $t } from '@interactivevision/translations';
import { Button } from '../components/button';
import { useSetAtom } from "jotai";
import { atomModalContact } from '../atom/atoms';


export default function Navbar() {
  const setContactModal = useSetAtom( atomModalContact );


  return (
    <nav className={styles.navbar} id="navbar">
      <div className={styles.content}>
        <Link href={route( 'homepage_module' )} className={styles.logo} title={import.meta.env.VITE_APP_NAME}>
          <img src={CompanyLogo} alt="CapitalHome - logo" />
        </Link>
        <Button className={styles.contact} aria-label={$t( 'general.contact_us' )} onClick={() => setContactModal( true )}>
          Masz pytania?
        </Button>
      </div>
    </nav>
  );
};
