import styles from "./base.module.scss";
import Navbar from "../layout/navbar";
import { Provider as JotaiProvider } from "jotai";
import Footer from "./footer";

export const Base = ( { children } ) => {
  return (
    <JotaiProvider>
      <Navbar />
      <main className={styles.base}>{children}</main>
    </JotaiProvider>
  );
};
